.partsmodal {
    position: absolute;
    bottom: -100%;
    left: 0;
    right: 0;
    margin: auto;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    align-items: flex-end;
    z-index: 2;
    background-color: #b3d0ff80;
    transition: all 0.2s ease-in-out;
    &.active {
        bottom: 0;
    }
    &_body {
        width: 100%;
        background-color: #fff;
        border-top-right-radius: 40px;
        border-top-left-radius: 40px;
        padding: 30px;
        @include bp(mob) {
            padding: 20px 16px;
            border-top-right-radius: 25px;
            border-top-left-radius: 25px;
        }
    }
    &_header {
        display: flex;
        justify-content: space-between;
        margin-bottom: 25px;
        h2 {
            font-family: $Inter;
            font-size: 24px;
            font-weight: 500;
            @include bp(mob) {
                font-size: 18px;
            }
        }
        span {
            cursor: pointer;
            svg {
                width: 20px;
                height: 20px;
                @include bp(mob) {
                    width: 15px;
                    height: 15px;
                }
            }
        }
    }
    &_wrp {
        height: 350px;
        overflow: auto;
        padding-right: 5px;
        &_item {
            display: flex;
            align-items: center;
            gap: 15px;
            margin-bottom: 15px;
            position: relative;
            input:checked+label {
                border-color: #0957DE;
                div {
                    h3 {
                        &::before {
                            border: 5px solid #0957DE;
                        }
                    }
                    p {
                        color: #0957DE;
                    }
                }
            }
            label {
                padding: 15px;
                border-radius: 15px;
                border: 2px solid #d5d5d5;
                display: block;
                width: 100%;
                padding-left: 40px;
                cursor: pointer;
                &:hover {
                    border-color: #0957DE;
                }
                div {
                    h3 {
                        color: #666666;
                        font-size: 18px;
                        position: relative;
                        margin-bottom: 10px;
                        font-weight: 500;
                        padding-right: 30px;
                        @include bp(mob) {
                            font-size: 12px;
                            padding-right: 15px;
                        }
                        &::before {
                            content: "";
                            position: absolute;
                            width: 20px;
                            height: 20px;
                            left: -30px;
                            display: block;
                            border: 2px solid #999999;
                            border-radius: 50%;
                            @include bp(mob) {
                                width: 16px;
                                height: 16px;
                            }
                        }
                    }
                    p {
                        font-size: 30px;
                        font-weight: 700;
                        font-family: $Inter;
                        color: #000;
                        @include bp(mob) {
                            font-size: 20px;
                        }
                    }
                }
            }
            span {
                bottom: 0;
                cursor: pointer;
                font-family: monospace;
                position: absolute;
                right: 15px;
                width: 40px;
                height: 40px;
                background-color: #b3cfff;
                border-radius: 50%;
                margin: auto;
                top: 0;
                text-align: center;
                align-items: center;
                display: flex;
                justify-content: center;
                font-size: 15px;
                @include bp(mob) {
                    font-size: 18px;
                    width: 33px;
                    height: 33px;
                }
            }
        }
    }
}