.details {
    padding: 40px;
    background-color: #4183E6;
    border-bottom-left-radius: 30px;
    border-bottom-right-radius: 30px;
    padding-bottom: 50px;
    @include bp(mob) {
        padding: 20px;
    }
    &_content {
        padding: 40px;
        height: calc(100vh - 251px);
        overflow: auto;
        @include bp(mob) {
            padding: 20px;
            height: calc(100vh - 140px);
        }
        .dashboard_wrp {
            margin-top: 10px;
        }
        .other_details {
            &:last-child {
                margin-top: 0;
            }
        }
    }
    header {
        position: relative;
        text-align: center;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 45px;
        @include bp(mob) {
            margin-bottom: 25px !important;
        }
        @include bp(480px) {
            margin: 0 -15px;
        }
        button {
            width: 36px;
            height: 36px;
            border-radius: 18px;
            background: transparent;
            border: 1px solid transparent;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0;
            @include bp(mob) {
                width: 36px;
                height: 36px;
                border-radius: 10px;
                padding: 0;
            }
            svg {
                width: 30px;
                height: 30px;
                display: block;
                margin: auto;
                object-fit: cover;
                @include bp(mob) {
                    width: 25px;
                    height: 25px;
                }
                path {
                    fill: #fff;
                }
            }
            &.back {
                svg {
                    path {
                        stroke: #fff;
                        fill: #4183E6;
                    }
                }
            }
        }
        .header_center {
            h1 {
                width: unset;
            }
        }
        h1 {
            width: calc(100% - 120px);
            padding: 13px 15px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-weight: 400;
            color: #ffffff;
            font-size: 28px;
            margin: auto;
            @include bp(mob) {
                padding: 7px 12px;
                font-size: 4.5vw;
            }
            @include bp(480px) {
                font-size: 5vw;
            }
        }
        .header_center {
            width: 100%;
        }
        .header_left {
            width: 76px;
            display: flex;
            justify-content: flex-start;
        }
        .header_right {
            width: 76px;
            display: flex;
            gap: 5px;
            @include bp(mob) {
                gap: 0;
            }
        }
    }
    .user_deatil {
        display: flex;
        gap: 20px;
        justify-content: space-between;
        align-items: flex-start;
        h2 {
            font-weight: 400;
            font-size: 29px;
            color: #fff;
            width: calc(100% - 145px);
            span {
                cursor: pointer;
            }
            @include bp(mob) {
                width: calc(100% - 65px);
                font-size: 22px;
            }
        }
        .balance {
            width: 125px;
            height: 56px;
            border-radius: 32px;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0 5px;
            color: #272727;
            font-size: 24px;
            background-color: #FFC56E;
            @include bp(mob) {
                width: 50px;
                height: 36px;
                font-size: 14px;
                border-radius: 12px;
            }
        }
    }
}
.parts_details {
    border: 1px solid #AEAEAE;
    max-width: 476px;
    height: 129px;
    border-radius: 21px;
    text-align: center;
    margin: auto;
    margin-top: 27px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    margin-bottom: 41px;
    cursor: pointer;
    text-transform: capitalize;
    @include bp(mob) {
        font-size: 18px;
        border-radius: 18px;
    }
}
.timer_btn {
    background-color: #FFC56E;
    color: #3A3A3A;
    font-size: 28px;
    max-width: 317px;
    height: 67px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 38px;
    margin: auto;
    cursor: pointer;
    letter-spacing: 0.05em;
    box-shadow: 0px 0px 15px #0000003b;
    @include bp(mob) {
        font-size: 18px;
        height: 54px;
        border-radius: 18px;
    }
}
.start_btn {
    margin: auto;
    width: 173px;
    height: 173px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    background: #DCE5FF;
    box-shadow: 0px 5px 10px rgba(65, 131, 230, 0.301961);
    cursor: pointer;
    @include bp(mob) {
        font-size: 18px;
        width: 152px;
        height: 152px;
    }
    span {
        margin: auto;
        width: 145px;
        height: 145px;
        background: #4183E6;
        box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.321569);
        border-radius: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        font-size: 27px;
        @include bp(mob) {
            font-size: 18px;
            width: 124px;
            height: 124px;
        }
    }
}
//popup
.parts_table {
    .popup_content_wrp {
        padding: 0;
        max-height: calc(100vh - 74px);
    }
    form {
        height: 100% !important;
    }
    &_wrp {
        height: calc(100% - 56px);
        overflow: auto;
        padding-bottom: 20px;
        &_table {
            max-height: 290px;
            overflow: auto;
            margin-top: 20px;
            margin-bottom: 15px;
            table {
                width: 100%;
                font-size: 18px;
                border-collapse: collapse;
                font-weight: normal;
                @include bp(480px) {
                    font-size: 12px;
                }
            }
            td,
            th {
                padding: 15px;
                text-align: left;
                font-weight: normal;
                @include bp(480px) {
                    padding: 10px 5px;
                }
            }
            thead tr {
                background-color: #4183E6;
                color: #fff;
            }
            tbody tr:nth-child(odd) {
                background-color: #f8f9fa;
            }
            tbody tr:nth-child(even) {
                background-color: #e9ecef;
            }
        }
    }
    ul {
        li {
            padding: 10px 0;
            label {
                position: relative;
                cursor: pointer;
                font-size: 18px;
            }
            input {
                &:checked+label::after {
                    content: "";
                    display: block;
                    position: absolute;
                    top: 6px;
                    left: 5px;
                    background-color: #0957DE;
                    width: 11px;
                    height: 11px;
                    margin: auto;
                    border-radius: 50%;
                }
            }
            input+label {
                cursor: pointer;
                position: relative;
                padding-left: 30px;
                display: flex;
                align-items: flex-start;
                gap: 10px;
                font-size: 14px;
                line-height: 22px;
                justify-content: space-between;
                &::before {
                    content: '';
                    position: absolute;
                    width: 21px;
                    height: 21px;
                    left: 0;
                    display: block;
                    border: 2px solid #0957DE;
                    border-radius: 50%;
                }
                &::after {
                    top: 5px !important;
                }
            }
            .custom {
                margin-top: 10px;
                max-width: 100px;
            }
        }
    }
    fieldset {
        padding: 0;
        max-width: 200px;
        margin-top: 10px;
    }
    &_btn {
        display: flex;
        align-items: center;
        gap: 25px;
        @include bp(mob) {
            gap: 15px;
        }
        span, button {
            width: 50%;
            padding: 15px 20px;
            border: 1px solid #49A02C;
            color: #fff;
            background-color: #49A02C;
            transition: all 0.2s ease-in-out;
            margin: auto;
            display: block;
            font-size: 20px;
            text-align: center;
            cursor: pointer;
            border-radius: 10px;
            &.close_btn {
                background-color: $black;
                border-color: $black;
            }
            @include bp(mob) {
                padding: 15px 10px;
                font-size: 15px;
                border-radius: 5px;
            }
        }
    }
}
.confirmopup {
    background-color: #00000057 !important;
    .popup_content {
        max-width: 610px;
        width: 100%;
        height: 480px;
        margin: auto;
        top: 0;
        bottom: 0;
        background-color: #fff;
        padding: 20px;
        left: 0;
        right: 0;
        &_wrp {
            max-height: calc(100% - 50px);
        }
        .parts_table_wrp {
            height: calc(100% - 126px);
            max-height: 270px;
            margin-top: 20px;
            margin-bottom: 40px;
            &_table {
                max-height: 275px;
                margin-top: 20px;
                margin-bottom: 3px;
            }
        }
    }
}
.infopopup {
    background-color: #00000057 !important;
    .popup_content {
        max-width: 610px;
        width: 100%;
        height: 100vh;
        margin: auto;
        top: 0;
        bottom: 0;
        background-color: #fff;
        padding: 20px;
        left: 0;
        right: 0;
        &_wrp {
            max-height: calc(100% - 110px);
        }
        ul {
            width: 100%;
            li {
                display: inline-flex;
                margin-bottom: 10px;
                width: 100%;
                font-weight: 500;
                color: #222222;
                position: relative;
                @include bp(mob) {
                    flex-wrap: wrap;
                    margin-bottom: 15px;
                }
                h2 {
                    color: #0957DE;
                    font-weight: 700;
                    font-size: 18px;
                }
                label {
                    width: auto;
                    font-weight: 600;
                    text-transform: capitalize;
                    margin-right: 5px;
                    font-size: 14px;
                    @include bp(mob) {
                        margin-bottom: 5px;
                    }
                }
                span {
                    width: calc(100% - 200px);
                    text-transform: capitalize;
                    color: #666666;
                    font-size: 14px;
                    @include bp(mob) {
                        width: 100%;
                    }
                }
            }
        }
    }
    .next_button {
        background-color: #818181;
        border-color: #818181;
        margin-top: 0;
    }
}
.logout {
    position: absolute;
    right: 0;
    display: block;
    width: 30px;
    height: 30px;
    cursor: pointer;
    @include bp(mob) {
        width: 25px;
        height: 25px;
        right: 15px;
    }
}
.selectparts {
    width: 140px;
    padding: 10px;
    border-radius: 5px;
    display: block;
    color: #fff;
    background-color: #49A02C;
    margin-top: 11px;
    text-align: center;
    cursor: pointer;
    margin-bottom: 10px;
}

.addparts {
    margin-left: auto;
    width: 140px;
    padding: 10px;
    border-radius: 5px;
    display: block;
    color: #fff;
    background-color: #49A02C;
    margin-top: 11px;
    text-align: center;
    cursor: pointer;
    margin-bottom: 10px;
}
.editpart {
    background-color: #b2cfff !important;
    .popup_title {
        h2 {
            font-size: 30px;
        }
    }
    .appointment_wrp {
        height: calc(100vh - 133px);
        .appoint_form_wrp {
            padding: 0 6px;
        }
    }
}