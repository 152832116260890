@charset "utf-8";
/* CSS Document */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section,
summary {
  display: block;
}
body,
ol,
ul,
li,
div,
p,
span,
form,
h1,
h2,
h3,
h4,
h5,
h6,
table,
thead,
th,
tbody,
tr,
td,
img,
input,
textarea,
dd,
dt,
dl {
  word-wrap: break-word;
  outline: none;
  padding: 0;
  margin: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
textarea {
  overflow: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
ol,
dd,
dt,
dl {
  -webkit-margin-before: 0px;
  -webkit-margin-after: 0px;
  -webkit-margin-start: 0px;
  -webkit-margin-end: 0px;
}
a img,
img {
  border: none;
  outline: none;
}
input[type="submit"],
input[type="reset"],
input[type="button"],
button {
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  appearance: none;
}
form input[type="radio"],
form input[type="checkbox"] {
  width: 0;
  height: 0;
}
a {
  cursor: pointer;
  text-decoration: none;
  outline: 0;
}
.no-script-msg {
  font: 12px Arial, verdana, Helvetica, sans-serif;
  background: #ffffcc;
  width: auto;
  padding: 10px 10px 10px 25px;
  margin: 8px;
  border: 1px solid #cd3d18;
  font-weight: bold;
  height: auto;
  font-size: 11px;
  color: #000000;
  line-height: 150%;
  clear: both;
}
input {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "arial", sans-serif;
  -webkit-appearance: none;
}
::selection {
  text-shadow: none;
  background: rgba(65, 131, 196, 0.4);
}
.clear {
  clear: both;
  height: 0px;
  overflow: hidden;
  width: auto;
  display: block;
  float: none !important;
}
ul,
menu,
dir {
  margin: 0px;
  padding: 0px;
  display: block;
  list-style-type: disc;
  -webkit-margin-before: 0;
  -webkit-margin-after: 0;
  -webkit-margin-start: 0;
  -webkit-margin-end: 0;
  -webkit-padding-start: 0;
}
*,
*:after,
*:before {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
li {
  list-style: none;
}
//
body p {
  margin-bottom: 0;
}
// custom scrollbar
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}
::-webkit-scrollbar-track {
  background-color: transparent;
  -webkit-border-radius: 6px;
  border-radius: 6px;
}
::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: #B2CFFF;
}
.select-reset {
  border: none;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
fieldset {
  border: none;
}
.text-center {
  text-align: center;
}
input[type="text"], input[type="file"] {
  &:disabled {
    cursor: not-allowed !important;
  }
}