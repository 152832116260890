.popup {
    position: fixed;
    background-color: #fff;
    top: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 100%;
    max-width: 768px;
    height: 100%;
    z-index: 9999;
    display: flex;
    overflow: auto;
    flex-direction: column;
    padding: 30px 25px;
    padding-top: 50px;
    @include bp(mob) {
        padding: 15px;
    }
    &_title {
        text-align: center;
        margin-bottom: 15px;
    }
    form {
        height: calc(100% - 120px);
    }
    &_content {
        padding: 0;
        height: 100%;
        p {
            margin-bottom: 15px;
        }
        &_wrp {
            padding: 0 5px;
            height: 100%;
            overflow: auto;
            max-height: calc(100vh - 208px);
            @include bp(mob) {
                max-height: calc(100vh - 150px);
            }
        }
    }
    .paramters {
        &_item {
            display: block;
            margin-bottom: 35px;
            &:last-child {
                margin-bottom: 0;
            }
            h3 {
                font-weight: 400;
                color: #272727;
                font-size: 24px;
                margin-bottom: 15px;
                @include bp(mob) {
                    font-size: 18px;
                }
            }
            &_content {
                display: flex;
                flex-wrap: wrap;
                grid-template-columns: 1fr 1fr 1fr;
                display: grid;
                grid-gap: 10px;
                fieldset {
                    padding: 0;
                    width: 100%;
                    margin: 0;
                    text-align: center;
                    position: relative;
                    input {
                        &:checked+label {
                            border-color: #49A02C;
                            color: #fff;
                        }
                        &[type=radio], &[type=checkbox] {
                            position: absolute;
                            z-index: -1;
                            width: 2px;
                            height: 2px;
                            opacity: 0;
                            top: 0;
                            bottom: 0;
                            left: 0;
                            right: 0;
                            margin: auto;
                            -webkit-appearance: auto;
                        }
                    }
                    label {
                        padding: 13px 8px;
                        width: 100%;
                        color: #fff;
                        display: block;
                        border-radius: 10px;
                        font-size: 20px;
                        border: 4px solid transparent;
                        cursor: pointer;
                        letter-spacing: 0.5px;
                        @include bp(mob) {
                            border-radius: 2vw;
                            font-size: 3vw;
                            padding: 3vw 1vw;
                        }
                    }
                    &.very_light {
                        label {
                            background-color: #d1c6ca;
                        }
                    }
                    &.light {
                        label {
                            background-color: #beb7be;
                        }
                    }
                    &.medium {
                        label {
                            background-color: #c6b7ba;
                        }
                    }
                    &.medium_dark {
                        label {
                            background-color: #af977d;
                        }
                    }
                    &.dark {
                        label {
                            background-color: #907d81;
                        }
                    }
                    &.black {
                        label {
                            background-color: #8a8b90;
                        }
                    }
                }
            }
            &.haircolor {
                .paramters_item_content {
                    justify-content: center;
                    margin: auto;
                    grid-template-columns: 1fr 1fr;
                    display: grid;
                    grid-gap: 10px;
                    fieldset {
                        width: 100%;
                        &.black {
                            label {
                                background-color: #000000;
                            }
                        }
                        &.brown {
                            label {
                                background-color: #7f7a80;
                            }
                        }
                        &.light {
                            label {
                                background-color: #a0977a;
                            }
                        }
                        &.blonde {
                            label {
                                background-color: #d2bd86;
                            }
                        }
                    }
                }
            }
            &.hairdiameter {
                .paramters_item_content {
                    fieldset {
                        label {
                            background-color: #4183E6;
                            img {
                                max-width: 45px;
                                object-fit: contain;
                                @include bp(mob) {
                                    max-width: 9vw;
                                }
                            }
                            span {
                                display: block;
                            }
                        }
                    }
                }
            }
            &.workscreen {
                .paramters_item_content {
                    grid-template-columns: 1fr;
                    grid-gap: 0;
                    fieldset {
                        padding: 10px 0;
                        margin-bottom: 5px;
                        width: 100%;
                        text-align: left;
                        label {
                            padding: 0;
                            width: 100%;
                            display: block;
                            margin-bottom: 5px;
                            font-weight: 700;
                            color: #1C1B17;
                            font-size: 16px;
                            border: none;
                            cursor: default;
                            @include bp(mob) {
                                margin-bottom: 0;
                            }
                        }
                    }
                }
            }
        }
    }
    .feedback {
        &_item {
            margin-bottom: 35px;
            .que {
                width: 100%;
                display: flex;
                justify-content: space-between;
                gap: 30px;
                margin-bottom: 15px;
                p {
                    font-size: 18px;
                    width: calc(100% - 163px);
                    letter-spacing: 0.5px;
                    margin-bottom: 0;
                    line-height: 22px;
                    @include bp(mob) {
                        width: calc(100% - 58px);
                        font-size: 15px;
                        line-height: 19px;
                    }
                }
                .ans {
                    width: 163px;
                    display: flex;
                    font-size: 18px;
                    align-items: center;
                    gap: 15px;
                    @include bp(mob) {
                        font-size: 0;
                        width: 58px;
                        gap: 0;
                    }
                    .toggle label:before {
                        box-shadow: unset;
                    }
                }
            }
        }
    }
    &.termsPop {
        .popup_content_wrp {
            img {
                width: 100%;
                object-fit: contain;
            }
            p {
                font-size: 14px;
            }
        }
    }
    &_footer {
        margin-top: 15px;
        .blue_btn {
            @include bp(mob) {
                font-size: 20px;
                max-width: 320px;
                height: 50px;
            }
        }
    }
    &.breakpopup {
        background-color: #00000080;
        .popup_content {
            background-color: #fff;
            width: 100%;
            max-width: 450px;
            height: 305px;
            margin: auto;
            padding: 0 20px;
            .popup_title {
                margin: 15px 0;
            }
            fieldset {
                padding: 0;
                margin-bottom: 10px;
                margin-top: 15px;
                margin-bottom: 20px;
                display: inline-block;
                width: 100%;
                textarea {
                    border-radius: 5px;
                    border-color: #333;
                    width: 100%;
                    height: 130px;
                    max-height: 130px;
                    min-height: 130px;
                    padding: 10px;
                }
            }
        }
    }
}