@import "../mixins.scss";
.appointment {
  display: flex;
  position: relative;
  @include bp(mob) {
    padding: 0;
  }
  &_wrp {
    // padding-bottom: 20px;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    height: 100vh;
    background-color: #fff;
    header {
      position: relative;
      padding: 30px 20px;
      button {
        position: absolute;
        background-color: transparent;
        border: none;
        svg {
          @include bp(mob) {
            width: 34px;
          }
          path {
            stroke: $theme;
          }
        }
      }
      h1 {
        text-align: center;
        color: $theme;
        margin-bottom: 15px;
        @include bp(mob) {
          font-size: 18px;
          line-height: 36px;
        }
      }
    }
    .main_banner {
      background-color: #B2CFFF;
      position: relative;
      padding: 0 20px;
      padding-top: 85px;
      padding-bottom: 44px;
      text-align: center;
      &::after {
        content: "";
        position: absolute;
        right: 0;
        top: 0;
        background-image: url(../../../assets/images/banner_icon.svg);
        background-repeat: no-repeat;
        background-size: contain;
        width: 40%;
        height: 100%;
        @include bp(mob) {
          width: 50%;
        }
      }
      @include bp(mob) {
        padding-top: 55px;
        padding-bottom: 10px;
      }
      .logo {
        margin-bottom: 40px;
        img {
          display: block;
          width: 100%;
          max-width: 300px;
          margin: auto;
          @include bp(mob) {
            max-width: 50%;
          }
        }
      }
      .tab_section {
        max-width: 370px;
        background-color: #fff;
        border-radius: 50px;
        padding: 10px;
        margin: auto;
        position: relative;
        z-index: 1;
        ul {
          display: flex;
          justify-content: center;
          gap: 20px;
          li {
            width: 50%;
            display: flex;
            padding: 10px;
            align-items: center;
            cursor: pointer;
            border-radius: 50px;
            transition: all 0.3s ease-in-out;
            .tab_section_icon {
              width: 40px;
              height: 40px;
              border-radius: 50%;
              background-color: $white;
              display: flex;
              align-items: center;
              justify-content: center;
              margin-right: 11px;
              color: #1C1B17;
              transition: all 0.3s ease-in-out;
              img {
                width: 22px;
                @include bp(mob) {
                  width: 18px;
                }
              }
              @include bp(mob) {
                width: 28px;
                height: 28px;
              }
            }
            &.active, &:hover {
              background-color: #0957DE;
              span {
                color: $white;
              }
            }
            span {
              font-size: 23px;
              @include bp(mob) {
                font-size: 17px;
              }
            }
          }
        }
      }
      .visittype {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 15px;
        margin-top: 20px;
        font-size: 22px;
        @include bp(mob) {
          font-size: 17px;
        }
      }
    }
    .form_section {
      background-color: $white;
      padding: 25px 0;
      display: flex;
      width: 100%;
      .appoint_form {
        width: 100%;
        display: flex;
        flex-direction: column;
        &_wrp {
          height: 100%;
          padding: 0 20px;
          overflow: auto;
        }
        h3 {
          margin-bottom: 20px;
          margin-top: 15px;
          display: inline-block;
        }
        &_title {
          margin-bottom: 20px;
          &_panel {
            margin-bottom: 20px;
            input {
              &:checked+label .cost, &:hover+label .cost {
                background-color: #49A02C;
                color: #fff;
              }
            }
            label {
              cursor: pointer;
              position: relative;
              display: flex;
              align-items: center;
              gap: 10px;
              font-size: 18px;
              justify-content: space-between;
              .parts_left {
                display: flex;
                align-items: center;
                width: 100%;
                >div {
                  width: 80px;
                  height: 80px;
                  border-radius: 50%;
                  background-color: #0957DE;
                  margin-right: 20px;
                  padding: 3px;
                  display: flex;
                  @include bp(mob) {
                    width: 50px;
                    height: 50px;
                    margin-right: 10px;
                  }
                  img {
                    width: 100%;
                    object-fit: cover;
                    border-radius: 50%;
                    height: 100%;
                    display: block;
                  }
                }
                span {
                  width: calc(100% - 80px);
                  font-size: 26px;
                  font-weight: 500;
                  text-transform: capitalize;
                  @include bp(mob) {
                    font-size: 16px;
                  }
                }
              }
              ul {
                display: flex;
                align-items: center;
                font-size: 14px;
                color: #222222;
                li {
                  background-color: #0957DE;
                  color: #fff;
                  padding: 15px 20px;
                  font-size: 20px;
                  max-width: 125px;
                  width: 125px;
                  text-align: center;
                  border-radius: 10px;
                  font-weight: bold;
                  transition: all 0.2s ease-in-out;
                  @include bp(mob) {
                    padding: 12px 10px;
                    font-size: 13px;
                    max-width: 75px;
                    border-radius: 5px;
                  }
                }
              }
            }
          }
        }
      }
    }
    >.parts {
      height: 100%;
      background-color: #b2cfff;
      display: flex;
      flex-direction: column;
      .form_section {
        background-color: #b2cfff;
        height: 100%;
        overflow: auto;
        position: relative;
      }
      .appoint_form_wrp {
        &::-webkit-scrollbar-thumb {
          background: #0957DE;
        }
      }
    }
  }
}
.gender {
  input {
    &:checked+label::after {
      content: "";
      display: block;
      position: absolute;
      top: 5px;
      left: 4px;
      background-color: #0957DE;
      width: 12px;
      height: 12px;
      margin: auto;
      border-radius: 50%;
    }
  }
  input+label {
    cursor: pointer;
    position: relative;
    padding-left: 30px;
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 14px;
    line-height: 22px;
    justify-content: space-between;
    &::before {
      content: '';
      position: absolute;
      width: 20px;
      height: 20px;
      left: 0;
      display: block;
      border: 2px solid #0957DE;
      border-radius: 50%;
    }
  }
}
.next_button {
  max-width: 150px;
  width: 100%;
  padding: 12px 20px;
  border: 1px solid #49A02C;
  color: #fff;
  background-color: #49A02C;
  color: $white;
  transition: all 0.2s ease-in-out;
  margin: auto;
  display: block;
  margin-top: 30px;
  font-size: 23px;
  border-radius: 10px;
  @include bp(mob) {
    max-width: 131px;
    width: 100%;
    padding: 13px 20px;
    font-size: 19px;
  }
}