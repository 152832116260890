.confirmation {
    width: calc(100% - 40px);
    margin: auto;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: auto;
    .loader
    {
        background-color: #b3d0ff80;
    }
    &_wrp {
        background-color: #D8EFFF;
        padding: 20px;
        border-radius: 10px;
        display: flex;
        gap: 20px;
        border-radius: 20px;
        width: 100%;
        padding: 20px;
        overflow: auto;
        flex-wrap: wrap;
        ul {
            width: 100%;
            li {
                display: inline-flex;
                margin-bottom: 10px;
                width: 100%;
                font-weight: 500;
                color: #222222;
                position: relative;
                @include bp(mob) {
                    flex-wrap: wrap;
                    margin-bottom: 15px;
                }
                h2 {
                    color: #0957DE;
                    font-weight: 700;
                    font-size: 18px;
                }
                label {
                    width: auto;
                    font-weight: 600;
                    text-transform: capitalize;
                    margin-right: 5px;
                    font-size: 14px;
                    @include bp(mob) {
                        margin-bottom: 5px;
                    }
                }
                span {
                    width: calc(100% - 200px);
                    text-transform: capitalize;
                    color: #666666;
                    font-size: 14px;
                    @include bp(mob) {
                        width: 100%;
                    }
                }
                .edit {
                    position: absolute;
                    width: 45px;
                    height: 45px;
                    background-color: #fff;
                    border-radius: 50%;
                    right: 0;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;
                }
            }
        }
    }
}