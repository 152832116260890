.stepthree {
    height: calc(100vh - 185px);
    fieldset {
        display: flex;
        align-items: center;
        padding: 10px 0;
        flex-wrap: wrap;
        margin-bottom: 10px;
        @include bp(tab) {
            flex-wrap: wrap;
        }
        >label {
            width: 100%;
            display: block;
            margin-bottom: 5px;
            font-weight: 700;
            @include bp(mob) {
                margin-bottom: 0px;
            }
        }
        >div {
            gap: 10px;
            width: 100%;
            display: flex;
            @include bp(mob) {
                flex-wrap: wrap;
            }
            &.fname {
                flex-wrap: wrap;
            }
        }
        &.gender {
            label {
                margin-bottom: 5px;
            }
        }
        input[type="text"], input[type="email"], input[type="date"], input[type="number"], select {
            padding: 10px;
            width: 100%;
            border: none;
            border-bottom: 2px solid #0957DE;
            padding-left: 0;
            font-size: 14px;
            &:placeholder-shown {
                border-bottom: 1px solid #666666;
                font-weight: normal;
            }
            option {
                color: #666666;
            }
            &:focus {
                outline: none;
                border-bottom: 2px solid #0957DE;
            }
            @include bp(tab) {
                max-width: unset;
            }
        }
        .age {
            margin-top: 10px;
        }
    }
}
.req-red {
    color: red;
}
input[type="text"], input[type="email"], input[type="date"], input[type="number"], select {
    padding: 10px;
    width: 100%;
    border: none;
    border-bottom: 2px solid #0957DE;
    padding-left: 0;
    font-size: 14px;
    &:placeholder-shown {
        border-bottom: 1px solid #666666;
        font-weight: normal;
    }
    option {
        color: #666666;
    }
    &:focus {
        outline: none;
        border-bottom: 2px solid #0957DE;
    }
    @include bp(tab) {
        max-width: unset;
    }
}