// color varible
$theme: #1C1B17;
$themelight: #9efff5;
$lighttheme: #cfdadd;
$themetrans: #1c526066;
$tlight: #467c8a;
$white: #ffffff;
$black: #000000;
$blacklight: #444444;
$blacknoti: #353535;
$grey: #777777;
$darkgrey: #666666;
$shadegrey: #eee;
$lightgrey: #b4b5b6;
$vlightgrey: #cfcfcf;
$lightbg: #f8f8f8;
$newblack: #292929;
$blackgrey: #333333;
$suvagrey: #909090;
$bgrey: #999999;
$purple: #4e3f6d;
$purplelight: #8c7dab;
$nobel: #969696;
$tutu: #ffe6e6;
$purpleshade: #a39fb2;
$manatee: #8f9094;
$quartz: #dcdce6;
$gainsboro: #dedede;
$snow: #fafafa;
$gainsborolight: #dcd6d6;
$graydark: #ababab;
$popup_head_bg: #efefef;
$borderlightred: #cd4245;
$inputcolor: #575757;
$pink: #fd135b;
$pinkt: #fd215f;
$neonpink: #e76d89;
$lightpink: #ffebf5;
$separator: #c9c9c9;
$red: #d53131;
$redorange: #ff3232;
$radishpink: #ff003f;
$freespeechred: #cc0000;
$carissma: #e58c77;
$btnshadow: #0000006e;
$profileShadow: #0000006e;
$tablehead: #2b2e34;
$intPink: #fceefb;
$intborder: #cccccc;
$placeholder: #bbbbbb;
$outershadow: #000000bf;
$transblack: #00000080;
$whiteshadow: #ffffff8c;
$lightshadow: #ffffff38;
$btnback: #e1e1e1;
$lightback: #f6f6f7;
$deemBlack: #f9f9f9;
$fadeBlack: #fefefe;
$deem_bg: #e7edee;
$light_deem_bg: #fbfcfc;
$measurebarBack: #e9e9ea;
$whisper: #e9e9e9;
$lightwhisper: #e7e7e7;
$simpleborder: #c2c2c2;
$litegreen: #73d00f;
$olivedrab: #6b8e23;
$darkpurple: #b951ed;
$conch: #99b1b7;
$cream: #fb7474;
$pinkred: #e41759;
$reglay: #f9f4e9;
$boxtrans: #00000033;
$shopBack: #c4c4c4;
$lighblue: #c8e2e9;
$skyblue: #5eb0e5;
$bordercolor: #cecece;
$yellow: #f6cf4e;
// width variable
$laptopview: 1024px;
$tabview: 768px;
$mobileview: 576px;
// fonn variable
$Inter: "Inter";
/* media port */
$mini: mini;
$tab: tab;
$mob: mob;