.accordion {
    max-width: 600px;
    margin: 2rem auto;
    &-item {
        margin-bottom: 15px;
        &:nth-last-child(2) {
            margin-bottom: 0;
        }
    }
}
.accordion-title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    cursor: pointer;
    background-color: #4183e6;
    border: 1px solid #4183e6;
    color: #fff;
}
.accordion-title,
.accordion-content {
    padding: 1rem;
}
.accordion-content {
    background-color: #c1c1c133;
    border: 1px solid #4183e6;
    .parts_table_wrp {
        &_table {
            margin-top: 0;
            table {
                td, th {
                    font-size: 15px;
                    text-transform: capitalize;
                    @include bp(mob) {
                        font-size: 12px;
                    }
                }
                thead {
                    border: 1px solid #4183e6;
                }
                tbody {
                    border: 1px solid #cdcdcd;
                }
            }
            h3 {
                margin-bottom: 10px;
                font-size: 18px;
                @include bp(mob) {
                    font-size: 16px;
                }
            }
        }
        .preview_wrp {
            margin-bottom: 0;
        }
    }
}
.history {
    .popup_title {
        margin-bottom: 20px;
    }
    @include bp(mob) {
        padding: 15px 0;
    }
    .histotypopup {
        .parts_table_btn {
            margin-top: 20px;
        }
    }
    .perview_img {
        cursor: pointer;
    }
}
/* The Modal (background) */
.modal {
    display: none;
    position: fixed;
    z-index: 1;
    padding: 50px 10px;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgb(0, 0, 0);
    background-color: rgba(0, 0, 0, 0.9);
}
.modal-content {
    margin: auto;
    display: block;
    width: auto;
    height: 100%;
    object-fit: contain;
    max-width: 550px;
}
/* Add Animation - Zoom in the Modal */
.modal-content, #caption {
    -webkit-animation-name: zoom;
    -webkit-animation-duration: 0.6s;
    animation-name: zoom;
    animation-duration: 0.6s;
}
@-webkit-keyframes zoom {
    from {
        -webkit-transform: scale(0)
    }
    to {
        -webkit-transform: scale(1)
    }
}
@keyframes zoom {
    from {
        transform: scale(0)
    }
    to {
        transform: scale(1)
    }
}
/* The Close Button */
.close {
    position: absolute;
    top: 0;
    right: 25px;
    color: #f1f1f1;
    font-size: 40px;
    font-weight: bold;
    transition: 0.3s;
}
.close:hover,
.close:focus {
    color: #bbb;
    text-decoration: none;
    cursor: pointer;
}