.dashboard {
    padding: 40px;
    height: 100vh;
    overflow: auto;
    background: #fff;
    @include bp(mob) {
        padding: 20px;
    }
    header {
        position: relative;
        text-align: center;
        display: flex;
        justify-content: space-between;
        align-items: center;
        @include bp(480px) {
            margin: 0 -15px;
        }
        button {
            width: 36px;
            height: 36px;
            border-radius: 18px;
            background: transparent;
            border: 1px solid transparent;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0;
            @include bp(mob) {
                width: 36px;
                height: 36px;
                border-radius: 10px;
                padding: 0;
            }
            svg {
                width: 30px;
                height: 30px;
                display: block;
                margin: auto;
                object-fit: cover;
                @include bp(mob) {
                    width: 25px;
                    height: 25px;
                }
                path {
                    fill: #4183E6;
                }
            }
            &.back {
                svg {
                    path {
                        stroke: #4183E6;
                        fill: #fff;
                    }
                }
            }
        }
        .header_center {
            h1 {
                width: unset;
            }
        }
        h1 {
            width: calc(100% - 120px);
            padding: 13px 15px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-weight: 400;
            color: #272727;
            font-size: 28px;
            @include bp(mob) {
                padding: 7px 10px;
                font-size: 4.5vw;
            }
            @include bp(480px) {
                font-size: 5vw;
            }
        }
        .header_left {
            width: 76px;
            display: flex;
            justify-content: flex-start;
        }
        .header_right {
            width: 76px;
            display: flex;
            gap: 5px;
            @include bp(mob) {
                gap: 0;
            }
        }
    }
    &_wrp {
        margin-top: 86px;
        @include bp(mob) {
            margin-top: 0;
        }
        .technician {
            .view_all {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 35px;
                h2 {
                    font-family: $Inter;
                    font-size: 22px;
                    color: #272727;
                    font-weight: 400;
                    padding-right: 5px;
                    @include bp(mob) {
                        font-size: 17px;
                    }
                }
                span {
                    font-weight: 400;
                    font-size: 18px;
                    color: #4183E6;
                    padding-left: 5px;
                    cursor: pointer;
                }
            }
            &_list {
                padding: 0 20px;
                @include bp(mob) {
                    padding: 0px;
                }
                &_item {
                    background-color: #4183E6;
                    border-radius: 30px;
                    padding: 30px;
                    margin-bottom: 30px;
                    cursor: pointer;
                    @include bp(mob) {
                        padding: 15px;
                        border-radius: 25px;
                    }
                    &:last-child {
                        margin-bottom: 0;
                    }
                    &_header {
                        width: 100%;
                        display: flex;
                        align-items: center;
                        position: relative;
                        padding-right: 50px;
                        margin-bottom: 40px;
                        @include bp(mob) {
                            padding-right: 9.5vw;
                            margin-bottom: 3vw;
                        }
                        .user {
                            margin-right: 10px;
                            border-radius: 50%;
                            border: 2px solid #fff;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            min-width: 98px;
                            height: 98px;
                            background-color: #e1dfe0;
                            @include bp(mob) {
                                min-width: 15vw;
                                height: 15vw;
                            }
                            img {
                                width: 60px;
                                height: 60px;
                                display: block;
                                object-fit: cover;
                                @include bp(mob) {
                                    width: 10vw;
                                    height: 10vw;
                                    display: block;
                                    object-fit: contain;
                                }
                            }
                            &_name {
                                padding: 32px;
                                background-color: #3e7cda;
                                border-radius: 18px;
                                width: 100%;
                                max-width: 450px;
                                color: #fff;
                                @include bp(mob) {
                                    border-radius: 3vw;
                                    color: #fff;
                                    padding: 4.5vw;
                                }
                                h3 {
                                    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
                                    font-weight: 400;
                                    font-size: 24px;
                                    @include bp(mob) {
                                        font-size: 4vw;
                                    }
                                }
                            }
                            &_info {
                                position: absolute;
                                right: 0;
                                top: 0;
                                cursor: pointer;
                                img {
                                    width: 28px;
                                    display: block;
                                    object-fit: contain;
                                    @include bp(mob) {
                                        width: 5.3vw;
                                    }
                                }
                            }
                        }
                    }
                    &_tl {
                        width: 100%;
                        display: flex;
                        gap: 30px;
                        @include bp(mob) {
                            gap: 3vw;
                        }
                        div {
                            width: 100%;
                            padding: 20px;
                            background-color: #3e7cda;
                            border-radius: 18px;
                            color: #fff;
                            display: flex;
                            align-items: center;
                            @include bp(mob) {
                                padding: 3vw;
                                border-radius: 3vw;
                            }
                            img {
                                margin-right: 20px;
                                width: 30px;
                                height: 30px;
                                display: block;
                                object-fit: contain;
                                @include bp(mob) {
                                    width: 5vw;
                                    margin-right: 3vw;
                                }
                            }
                            span {
                                font-size: 20px;
                                @include bp(mob) {
                                    font-size: 3vw;
                                }
                            }
                        }
                    }
                }
            }
        }
        .other_details {
            margin-top: 50px;
            h2 {
                font-weight: 400;
                color: #272727;
                font-size: 24px;
                @include bp(mob) {
                    font-size: 18px;
                }
            }
            .item_wrp {
                padding: 40px;
                position: relative;
                @include bp(mob) {
                    padding: 20px 15px;
                }
            }
            .upload_image {
                position: relative;
                label {
                    padding: 72px 0;
                    width: 100%;
                    background-color: #E9E9E9;
                    border-radius: 40px;
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;
                    justify-content: center;
                    align-content: center;
                    cursor: pointer;
                    @include bp(mob) {
                        padding: 50px 0;
                    }
                    img {
                        display: block;
                        @include bp(mob) {
                            width: 60px;
                        }
                    }
                    span {
                        display: block;
                        width: 100%;
                        color: #8D8D8D;
                        font-size: 24px;
                        text-align: center;
                        @include bp(mob) {
                            font-size: 18px;
                        }
                    }
                }
                input {
                    position: absolute;
                    top: 0;
                    right: 0;
                    left: 0;
                    bottom: 0;
                    margin: auto;
                    opacity: 0;
                    cursor: pointer;
                }
            }
        }
        .concern {
            margin-bottom: 15px;
            &_wrp {
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding-right: 40px;
                flex-wrap: wrap;
                @include bp(mob) {
                    padding-right: 20px;
                }
                h2 {
                    padding-right: 10px;
                }
            }
            .timer_btn {
                background-color: #FFC56E;
                color: #3A3A3A;
                font-size: 28px;
                max-width: 317px;
                height: 67px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 38px;
                margin: auto;
                cursor: pointer;
                letter-spacing: 0.05em;
                box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.231373);
                @include bp(mob) {
                    font-size: 18px;
                    height: 54px;
                    border-radius: 18px;
                }
            }
        }
        .blue_btn {
            font-size: 28px;
            width: 100%;
            max-width: 317px;
            height: 67px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 38px;
            margin: auto;
            cursor: pointer;
            border: none;
            background-color: #4183E6 !important;
            color: #fff !important;
            letter-spacing: 0.05em;
            box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.231373);
        }
        .process {
            .blue_btn {
                height: 102px;
                font-size: 21px;
                max-width: 393px;
                border-radius: 21px;
                @include bp(mob) {
                    font-size: 17px;
                    border-radius: 18px;
                    height: 82px;
                }
            }
        }
        .Advice {
            .blue_btn {
                font-size: 21px;
                border-radius: 21px;
                max-width: 477px;
                height: 80px;
                margin-bottom: 30px;
                &:last-child {
                    margin-bottom: 0;
                }
                @include bp(mob) {
                    height: 50px;
                    font-size: 17px;
                    border-radius: 18px;
                    margin-bottom: 20px;
                }
            }
        }
    }
}
.toggle {
    position: relative;
    box-sizing: border-box;
    input[type="checkbox"] {
        position: absolute;
        left: 0;
        top: 0;
        z-index: 10;
        width: 100%;
        height: 100%;
        cursor: pointer;
        opacity: 0;
        &:checked {
            + {
                label {
                    &:before {
                        background: #4183E6;
                    }
                    &:after {
                        left: 42px;
                        @include bp(mob) {
                            left: 32px;
                        }
                    }
                }
            }
        }
    }
    label {
        position: relative;
        display: flex;
        align-items: center;
        box-sizing: border-box;
        &:before {
            content: '';
            width: 78px;
            height: 40px;
            background: #989898;
            position: relative;
            display: inline-block;
            box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.231373);
            border-radius: 38px;
            box-sizing: border-box;
            transition: 0.2s ease-in;
            @include bp(mob) {
                width: 58px;
                height: 30px;
            }
        }
        &:after {
            content: '';
            position: absolute;
            width: 32px;
            height: 32px;
            border-radius: 50%;
            left: 5px;
            top: 4px;
            z-index: 2;
            background: #ECECEC;
            box-sizing: border-box;
            transition: 0.2s ease-in;
            @include bp(mob) {
                width: 22px;
                height: 22px;
            }
        }
    }
}
.select_date {
    display: flex;
    justify-content: space-between;
    gap: 32px;
    margin-top: 80px;
    @include bp(mob) {
        margin-bottom: 30px;
        gap: 20px;
        margin-top: 40px;
    }
    >div {
        width: 50%;
        .react-datepicker__input-container {
            input {
                width: 100%;
                text-align: center;
                padding: 20px 15px;
                border-radius: 20px;
                background-color: #FFC56E;
                border: none;
                font-size: 20px;
                color: #272727;
                @include bp(mob) {
                    padding: 16px 14px;
                    border-radius: 13px;
                    font-size: 17px;
                }
            }
        }
    }
    label {
        font-size: 24px;
        display: block;
        text-align: center;
        color: #727272;
        margin-bottom: 20px;
        @include bp(mob) {
            font-size: 18px;
            margin-bottom: 10px;
        }
    }
}
.disable {
    opacity: 0.5;
    pointer-events: none;
}
.searchbar {
    margin: 10px 0;
    margin-top: 25px;
    display: block;
    input {
        height: 55px;
        font-size: 18px;
    }
}
.preview_wrp {
    display: flex;
    overflow: auto;
    gap: 15px;
    margin-bottom: 15px;
}
.perview_img {
    text-align: center;
    img {
        width: 120px;
        height: 120px;
        object-fit: cover;
        border: 1px solid;
        border-radius: 6px;
        display: block;
        margin: auto;
        padding: 10px;
        @include bp(mob) {
            width: 80px;
            height: 80px;
        }
    }
}
.perview_video {
    text-align: center;
    video {
        width: 300px;
        height: 160px;
        display: block;
        margin: auto;
    }
}