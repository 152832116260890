body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: $Inter;
  font-weight: normal;
  overflow: unset !important;
  overflow-x: hidden !important;
  min-width: unset !important;
  font-size: 16px;
  padding: 0;
  color: $theme;
  background-color: #f5f5f5;
}
.container {
  margin: auto;
  max-width: 768px;
  width: 100%;
  padding: 0;
  position: relative;
}
img {
  width: auto;
  height: auto;
  margin: auto;
}
.bg_pink {
  background: $lightpink;
  width: 100%;
}
.bg_blue {
  background: $theme;
  width: 100%;
}
.bg_body {
  background: $deemBlack;
}
.bg_white {
  background: $white !important;
  width: 100%;
}
.no_bg {
  background: none;
  width: 100%;
}
.grey-btn {
  @include grey-btn;
}
.text-input {
  @include log-input;
  &.error {
    border-color: red;
  }
}
.bg_light {
  background: $deemBlack;
}
.d_flex {
  @include displayflex;
}
input[type="radio"],
input[type="checkbox"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  position: absolute;
  left: -9999px;
}
button {
  outline: none;
  cursor: pointer;
}
.g_notfount_product {
  text-align: center;
  margin-bottom: 10px;
}
.disabledBtn {
  cursor: auto !important;
  -webkit-user-select: none;
  user-select: none !important;
}
.disabledBtnopacity {
  cursor: auto !important;
  -webkit-user-select: none;
  user-select: none !important;
  opacity: 0.5 !important;
}
.enableBtn {
  pointer-events: all;
  user-select: auto;
}
.nodataFound {
  text-align: center;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  margin: 0 !important;
  padding: 10px;
  text-transform: uppercase;
  width: 100%;
  border-radius: 3px;
  font-family: $Inter;
  &.normal {
    padding: 30px 10px;
    text-transform: unset;
  }
  &.ttunset {
    text-transform: unset;
  }
  .anchor {
    cursor: pointer;
    transition: all 0.5s ease;
    text-decoration: underline;
    &:hover {
      color: $radishpink;
    }
  }
  .link {
    background: transparent;
    padding: 0;
    border: none;
  }
}
.center {
  text-align: center;
  justify-content: center;
}
.disable_btn {
  pointer-events: none;
  opacity: 0.7;
}
.clear {
  clear: both;
}
.capitalize-msg {
  text-transform: capitalize;
}
input::-moz-focus-outer {
  border: 0;
}
.cursor {
  cursor: pointer;
}
label {
  font-weight: normal;
}
.line-through {
  text-decoration: line-through;
  display: inline !important;
}
.loader {
  position: absolute;
  z-index: 99999;
  height: 100%;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  span {
    display: block;
    width: 40px;
    height: 40px;
    border: 3px solid;
    border-radius: 50%;
    border-color: transparent #000 #000;
    -webkit-animation: cssload-spin 690ms infinite linear;
    animation: cssload-spin 690ms infinite linear;
  }
}
@keyframes cssload-spin {
  100% {
    transform: rotate(360deg);
    transform: rotate(360deg);
  }
}