.home_dash {
    height: calc(100vh - 251px) !important;
    @include bp(mob) {
        height: calc(100vh - 159px) !important;
    }
    .dashboard_wrp {
        margin-top: 0;
    }
    &_wrp {
        display: grid;
        gap: 20px;
        flex-wrap: wrap;
        grid-template-columns: repeat(2, 1fr);
        @include bp(mob) {
            gap: 10px;
        }
        &_item {
            background-color: #e2e7ed;
            padding: 4px;
            cursor: pointer;
            .btn_icon {
                display: flex;
                align-items: center;
                justify-content: center;
                text-align: center;
                margin: 25px auto;
                img {
                    width: 120px;
                    margin: auto;
                    object-fit: contain;
                    display: block;
                    @include bp(mob) {
                        width: 70px;
                    }
                }
            }
            span {
                width: 100%;
                padding: 10px;
                background-color: #4183E6;
                display: block;
                text-align: center;
                color: #fff;
                @include bp(mob) {
                    padding: 10px;
                    font-size: 13px;
                }
            }
        }
    }
}