/* inter-regular - latin */
@font-face {
  font-display: swap;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  src:
    url('../../../assets/fonts/inter-v12-latin-regular.woff2') format('woff2'),
    /* Super Modern Browsers */
    url('../../../assets/fonts/inter-v12-latin-regular.woff') format('woff')
}
/* inter-500 - latin */
@font-face {
  font-display: swap;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  src:
    url('../../../assets/fonts/inter-v12-latin-500.woff2') format('woff2'),
    /* Super Modern Browsers */
    url('../../../assets/fonts/inter-v12-latin-500.woff') format('woff')
}
/* inter-600 - latin */
@font-face {
  font-display: swap;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  src:
    url('../../../assets/fonts/inter-v12-latin-600.woff2') format('woff2'),
    /* Super Modern Browsers */
    url('../../../assets/fonts/inter-v12-latin-600.woff') format('woff')
}
/* inter-700 - latin */
@font-face {
  font-display: swap;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  src:
    url('../../../assets/fonts/inter-v12-latin-700.woff2') format('woff2'),
    /* Super Modern Browsers */
    url('../../../assets/fonts/inter-v12-latin-700.woff') format('woff')
}
/* inter-800 - latin */
@font-face {
  font-display: swap;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 800;
  src:
    url('../../../assets/fonts/inter-v12-latin-800.woff2') format('woff2'),
    /* Super Modern Browsers */
    url('../../../assets/fonts/inter-v12-latin-800.woff') format('woff')
}
/* inter-900 - latin */
@font-face {
  font-display: swap;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 900;
  src:
    url('../../../assets/fonts/inter-v12-latin-900.woff2') format('woff2'),
    /* Super Modern Browsers */
    url('../../../assets/fonts/inter-v12-latin-900.woff') format('woff')
}