@import "../mixins.scss";
.login {
  height: 100vh;
  background-color: #fff;
  overflow: auto;
  &_header {
    padding: 50px 20px;
    padding-top: 180px;
    position: relative;
    overflow: hidden;
    padding-left: 66px;
    margin-bottom: 80px;
    @include bp(mob) {
      padding-top: 120px;
      padding-left: 36px;
      margin-bottom: 10px;
    }
    &::before {
      content: "";
      position: absolute;
      width: 292px;
      height: 292px;
      border-radius: 50%;
      top: -140px;
      left: -80px;
      display: block;
      background: #4183E6;
      @include bp(mob) {
        width: 235px;
        height: 235px;
      }
    }
    h2 {
      font-family: $Inter;
      font-weight: 400;
      font-size: 38px;
      line-height: 50px;
      @include bp(mob) {
        font-size: 30px;
        line-height: 40px;
      }
    }
  }
  form {
    padding: 0 20px;
    text-align: center;
    max-width: 510px;
    margin: auto;
    margin-bottom: 20px;
    @include bp(mob) {
      max-width: 490px;
    }
    div {
      margin-bottom: 20px;
      text-align: left;
      padding-left: 20px;
      border: 1.5px solid #BBBBBB;
      border-radius: 20px;
      padding: 15px 20px;
      padding-left: 40px;
      position: relative;
      @include bp(mob) {
        border-radius: 15px;
        padding: 8px 20px;
        padding-left: 40px;
      }
      img {
        position: absolute;
        width: 25px;
        left: 15px;
        top: 0;
        bottom: 0;
        margin: auto;
        display: block;
        @include bp(mob) {
          width: 20px;
        }
      }
      &.password {
        position: relative;
        input {
          padding-right: 40px;
        }
        span {
          position: absolute;
          right: 15px;
          display: flex;
          width: 25px;
          height: 25px;
          margin: auto;
          align-items: center;
          justify-content: center;
          top: 0;
          bottom: 0;
          cursor: pointer;
          img {
            position: unset;
          }
        }
      }
    }
    input {
      padding: 5px 10px;
      border: none !important;
      width: 100%;
      font-size: 18px;
      background-color: #fff !important;
      font-family: $Inter;
      border-left: 1px solid #4183E6;
      margin-left: 10px;
      padding-left: 20px;
      @include bp(mob) {
        font-size: 16px;
        padding-left: 15px;
      }
    }
    button {
      font-family: $Inter;
      font-size: 28px;
      line-height: 34px;
      letter-spacing: 0.03em;
      width: 100%;
      max-width: 380px;
      padding: 20px 15px;
      border-radius: 20px;
      background-color: #4183E6;
      border: none;
      color: #fff;
      display: inline-block;
      margin: 0 auto;
      margin-top: 80px;
      @include bp(mob) {
        font-size: 22px;
        line-height: 30px;
        letter-spacing: 0.03em;
        width: 100%;
        max-width: 200px;
        padding: 10px 15px;
        border-radius: 12px;
        background-color: #4183E6;
        margin-top: 30px;
        text-transform: capitalize;
      }
    }
  }
}