.select_date {
    width: 100%;
    overflow: auto;
    .stepthree {
        width: 100%;
    }
    .steptwo {
        padding: 0 20px;
        .customDatePickerWidth {
            text-align: center;
        }
        fieldset {
            justify-content: center;
            select {
                color: #0957DE;
                font-weight: bold;
            }
        }
    }
}
.custom_datepicker {
    display: flex;
    justify-content: space-evenly;
    max-width: 500px;
    margin: 0 auto;
    margin-bottom: 20px;
    gap: 10px;
    &_item {
        width: 33.33%;
        display: flex;
        flex-wrap: wrap;
        .next {
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
            background-color: #D8EFFF;
            padding: 6px 15px;
            border: none;
            color: #0957DE;
            width: 100%;
            font-size: 34px;
            &:hover {
                background-color: #0957DE;
                color: #fff;
            }
        }
        button:disabled {
            // opacity: 0.5;
            pointer-events: none;
        }
        .prev {
            border-bottom-left-radius: 10px;
            border-bottom-right-radius: 10px;
            background-color: #D8EFFF;
            padding: 6px 15px;
            border: none;
            width: 100%;
            color: #0957DE;
            font-size: 34px;
            &:hover {
                background-color: #0957DE;
                color: #fff;
            }
        }
        h2 {
            display: block;
            width: 100%;
            padding: 18px 15px;
            text-align: center;
            background-color: #D8EFFF;
            border-top: 1px solid #fff;
            border-bottom: 1px solid #fff;
            font-size: 22px;
            color: #0957DE;
        }
        &.dateslector {
            button, h2 {
                background-color: #0957DE;
                color: #fff;
            }
        }
    }
}